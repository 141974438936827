import Modal from "../components/Modal";
import { useNavigate } from "react-router";

const CookieConsentModal = (props) => {
  var cookieConsent = localStorage.getItem("cookieConsent");
  const navigate = useNavigate();

  if(cookieConsent !== undefined && cookieConsent === 'true'){
    return;
  }

  function setCookieAproved(){
    localStorage.setItem("cookieConsent", "true");
    props.onClose();
  }

  function cookieRejected(){
    navigate("/logout");
  }

  return (
    <Modal>
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title" id="exampleModalLongTitle">
            Използване на бисквитки
          </h4>
        </div>
        <div className="modal-body my-3 ms-3">
          <p>
            Да правим нещата по-сладки! 🍪 Нашите бисквитки <b>(cookies)</b> правят
            престоя ви на този сайт по-приятен. С натискането на бутона
            '<b>Приемам</b>' приемате използването на бисквитки. Ако не желаете, можете
            да откажете бисквитките чрез бутона '<b>Отказ</b>'. Изборът е ваш!
            Благодарим ви и приятно сърфиране! 🌟
          </p>
          <p>
            За повече информация, моля прочетете нашата{" "}
            <a href="/privacy-policy" target="_blank">
              политика за поверителност
            </a>
            .
          </p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-danger ms-1"
            data-dismiss="modal"
            // @ts-ignore
            onClick={() => cookieRejected()}
          >
            Отказ
          </button>
          <button
            type="button"
            className="btn btn-success ms-1"
            data-dismiss="modal"
            // @ts-ignore
            onClick={() => setCookieAproved()}
          >
            Съгласен съм
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CookieConsentModal;
